<template>
  <section v-bind="initData">
    <section class="section section-gallery" id="gallery" v-if="data">
      <div class="section-gallery-wrap">
        <div class="section-gallery-header">
          <GalleryBannerSlider :banner="data.banner" />
        </div>
        <div class="section-gallery-title">
          <h2 class="section-gallery-title-top sec-font">{{ data.languages[language].title }}</h2>
          <h3 class="section-gallery-title-sub">{{ data.languages[language].subtitle }}</h3>
        </div>
        <div class="section-gallery-filter">
          <div class="section-gallery-filter-content main">
            <VueSlickCarousel class="section-gallery-filter-slide" v-if="data.categories" v-bind="settings" ref="index">
              <div class="section-gallery-filter-category" v-for="(cat, cidx) in data.categories" :key="cidx">
                <router-link :to="{ path: '/gallery', hash: 'gallery', query: { filter: cat._id, id: cidx } }" inline-b>
                  <div class="section-gallery-filter-card" prevent>
                    <div :class="`section-gallery-filter-img-wrap ${cat.isNewVideo ? 'section-new' : ''}`">
                      <img :src="urlCloudFront(cat.pictureLink)" :alt="cat.alt" class="section-gallery-filter-img" />
                    </div>
                    <div class="section-gallery-filter-img-tittle" ellipsis>
                      {{ cat.hashtagText[language] }}
                    </div>
                  </div>
                </router-link>
              </div>
            </VueSlickCarousel>
          </div>
        </div>
        <div class="gallery-section-list">
          <router-view></router-view>
        </div>
      </div>
    </section>

    <div class="loading" v-else></div>
  </section>
</template>

<script>
  import VueSlickCarousel from "vue-slick-carousel";
  import GalleryBannerSlider from "@/components/web/GalleryBannerSlider.vue";

  import store from "../../store/index.js";
  import { mapActions, mapGetters } from "vuex";

  export default {
    components: { VueSlickCarousel, GalleryBannerSlider },
    data: function () {
      return {
        data: null,
        lang: null,
        categories: null,
        pictures: null,
        language: "es",
        selectedSlideIndex: 0,
        settings: {
          arrows: true,
          dots: false,
          slidesToShow: 5,
          slidesToScroll: 1,
          infinite: true,
          initialSlide: 0,
          responsive: [
            {
              breakpoint: 1440,
              settings: {
                slidesToShow: 4,
              },
            },
            {
              breakpoint: 1023,
              settings: {
                slidesToShow: 3,
              },
            },
            {
              breakpoint: 768,
              settings: {
                slidesToShow: 2,
                infinite: true,
                arrows: false,
                centerMode: false,
                variableWidth: true,
              },
            },
          ],
        },
      };
    },
    metaInfo: function () {
      const { gallery = {} } = this.locale;
      const { title = "", description = "", keywords = "" } = gallery;

      return {
        title,
        meta: [
          {
            vmid: "description",
            name: "description",
            content: description,
          },
          {
            vmid: "og:description",
            property: "og:description",
            content: description,
          },
          {
            vmid: "robots",
            name: "robots",
            content: "index, follow",
          },
          {
            vmid: "og:title",
            property: "og:title",
            content: title,
          },
          {
            vmid: "keywords",
            name: "keywords",
            content: keywords,
          },
        ],
      };
    },
    methods: {
      ...mapActions("gallery", ["getGallery"]),
      urlCloudFront(link) {
        return this.$global.newUrl(link);
      },
      getLang: async function () {
        if (store.getters["iflanguage"]) {
          this.lang = await store.getters["dataLang"];
        }
      },
    },
    computed: {
      ...mapGetters(["newLocale"]),
      initData: async function () {
        await this.getLang();
        this.language = localStorage.getItem("user-language");
        this.$refs.index.goTo(this.$route.query.id);
      },
      locale() {
        return this.newLocale;
      },
    },
    async beforeMount() {
      this.data = await this.getGallery();
      this.data.categories.reverse();
    },
  };
</script>

<style lang="scss">
  @import "@/assets/styles/vars.scss";
  .section-gallery {
    text-align: center;
    &-header {
      margin-bottom: 50px;
    }
    &-title {
      margin-top: -$mpadding * 1.3;
      &-top {
        font-size: 32px;
        color: $primary_color;
        font-weight: normal;
      }
      &-sub {
        font-size: 12px;
        line-height: 1.462;
        color: #5b5957;
        font-weight: normal;
        padding: 0 20px;
      }
    }
    &-filter {
      padding: $mpadding * 2 0;
      &-card {
        display: inline-block;
        box-shadow: 0 0 10px $alto;
        width: 187px;
        height: 220px;
        padding: 5px;
        overflow: hidden;
        background-color: white;
        max-width: 100%;
        margin: $mpadding/2;
        color: $primary_color;
      }
      &-img-wrap {
        height: 177px;
        background-color: $emperor;
        overflow: hidden;
        position: relative;
        &::after {
          position: absolute;
          visibility: hidden;
          top: 26px;
          right: -25px;
          content: "New";
          font-size: 10px;
          font-weight: bold;
          color: #fff;
          text-transform: uppercase;
          line-height: 20px;
          transform: rotate(45deg);
          width: 120px;
          background: $primary_color;
          box-shadow: 0 3px 10px -5px rgba(0, 0, 0, 1);
        }
        &.section-new {
          &::after {
            visibility: visible;
          }
        }
        &.section-new {
          position: relative;
        }
      }
      &-img {
        width: 100%;
        height: 100%;
        pointer-events: none;
      }
      &-card,
      &-img-wrap {
        border-radius: 12px;
      }
      &-img-tittle {
        text-align: center;
        margin-top: 7px;
        font-family: $sec_font;
        font-size: 20px;
      }
      .router-link-exact-active &-card {
        background-color: $primary_color;
        color: white;
      }
    }
    .slick-track {
      display: flex;
    }
    .slick-prev,
    .slick-next {
      z-index: 1;
      height: 100%;
      width: 27px;
    }
    .slick-prev::before,
    .slick-next::before {
      content: "";
      width: 100%;
      height: 100%;
      position: absolute;
      top: 0;
      left: 0;
    }
    .slick-prev {
      left: -40px;
    }
    .slick-next {
      right: -40px;
    }
    .slick-prev::before {
      background: url("/img/slide-left-hand.svg") no-repeat center;
    }
    .slick-next::before {
      background: url("/img/slide-right-hand.svg") no-repeat center;
    }
    @media (min-width: ($tablet_width+1)) {
      &-title {
        margin-top: -$mpadding * 1.7;
        &-top {
          font-size: 42px;
          font-weight: normal;
        }
        &-sub {
          font-size: 16px;
          line-height: 1.625;
        }
      }
      &-filter {
        &-slide {
          margin: 0 60px;
        }
      }
    }
    @media (min-width: $desktop_width) {
      &-filter {
        &-slide {
          margin: 0 60px;
        }
      }
    }
  }
</style>
